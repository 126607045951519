<template>
  <div class="renew">

    <header class="header" style="background-color: white"
      :class="{ 'fixed': fixed, 'header-white': white, 'is-top': windowTop }">
      <div class="header__inner">
        <h1 aria-label="weselpay" style="min-width: 300px">
          <router-link to="/" @click.native="goToMain">
            <img class="logo logo-white" src="@/assets/logo-new-white.svg" alt="" />
            <img class="logo" src="@/assets/logo-new.svg" alt="" />
            <span class="ml-8" style="color: black">가이드</span>
          </router-link>
        </h1>
        <nav id="topMenu" class="text-center">
          <v-tabs color="rgba(0, 74, 131, 1)" hide-slider next-icon="mdi-arrow-right"
                  prev-icon="mdi-arrow-left" show-arrows
                  @change="tabChanged"
                  v-model="tabIndex">
            <v-tab color="#EFEBE9" :value="0" >
              <label>홈</label>
            </v-tab>
            <v-tab color="#EFEBE9" :value="1" >
              <label>상품관리</label>
            </v-tab>
            <v-tab color="#EFEBE9" :value="2">
              <label >주문관리</label>
            </v-tab>
            <v-tab color="#EFEBE9" :value="3">
              <label>매출/정산</label>
            </v-tab>
            <v-tab color="#EFEBE9" :value="4">
              <label>회원관리</label>
            </v-tab>
            <v-tab color="#EFEBE9" :value="5">
              <label>혜택관리</label>
            </v-tab>
            <v-tab color="#EFEBE9" :value="6">
              <label>게시판관리</label>
            </v-tab>
            <v-tab color="#EFEBE9" :value="7">
              <label>메시지관리</label>
            </v-tab>
            <v-tab color="#EFEBE9" :value="8">
              <label>환경설정</label>
            </v-tab>
          </v-tabs>
        </nav>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    white: Boolean,
    fixed: Boolean,
  },
  data() {
    return {
      windowTop: true,
      navOpen: false,
      tabIndex: 0,
    };
  },
  created() {
    this.tabIndex=this.$route.meta.topMenuId
  },
  methods: {
    tabChanged(){
      if(this.tabIndex === 0){
        this.$store.commit('setGuideMenuId','index-home')
        this.$router.push( '/help/guide/home')
        return
      }

      if(this.tabIndex === 1){
        this.$store.commit('setGuideMenuId','product-list')
        this.$router.push( '/help/guide/product/list')
        return
      }
      if(this.tabIndex === 2){
        this.$store.commit('setGuideMenuId','order-all')
        this.$router.push( '/help/guide/order/all')
        return
      }
      if(this.tabIndex === 3){
        this.$router.push( '/help/guide/settlement')
        return
      }
      if(this.tabIndex === 4){
        this.$router.push( '/help/guide/member')
        return
      }
      if(this.tabIndex === 5){
        this.$router.push( '/help/guide/benefits/list')
        return
      }
      if(this.tabIndex === 6){
        this.$router.push( '/help/guide/order/list')
        return
      }
      if(this.tabIndex === 7){
        this.$router.push( '/help/guide/order/list')
        return
      }
      if(this.tabIndex === 8){
        this.$router.push( '/help/guide/order/list')
        return
      }
    },
    goToMain(){
      this.tabIndex=-1
      this.$router.push( '/help/guide/index')
    }
  },
};
</script>

<style scoped>

.header {
  position: fixed;
  border-bottom: 1px solid #dbe7ee;
  z-index: 10;
  transition: background-color 0.3s;
  width: 100%;
}

.header.fixed {
  background-color: #fff;
}

.header h1,
.header h1 a {
  display: inline-flex;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.logo {
  width: 140px;
  height: auto;
}

.logo-white {
  display: none;
}

.header-white .logo-white {
  display: block;
}

.header-white .logo:not(.logo-white) {
  display: none;
}

.header__inner nav ul {
  display: flex;
  gap: 32px;
  font-size: 18px;
}

.header__inner nav ul li a {
  color: #424242;
  padding: 10px 16px;
  border-radius: 4px;
  transition: 0.3s;
}

.header__inner nav ul li a:hover {
  background-color: rgba(66, 66, 66, 0.08);
}

.header-white .header__inner nav ul li a {
  color: #fff;
}

.header-white .header__inner nav ul li a:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.menu-btn {
  display: none;
  background: transparent;
  cursor: pointer;
}

.nav-overlay {
  display: none;
}


#topMenu .v-tabs,
#topMenu .v-tabs > div,
#topMenu .v-tabs > div > div,
#topMenu .v-tabs > div > div > div{
}

#topMenu .v-tabs .v-slide-group__content {
  justify-content: center;
}
#topMenu .v-tabs .v-tab {
  font-size: 20px;
  line-height: 1.15;
  margin: 0 10px;
  padding: 0 20px;
  letter-spacing: inherit;
}
#topMenu .v-tabs .v-tab--active {
  font-weight: 700;
}
#topMenu .v-tabs .v-tab label {
  cursor: pointer;
}


</style>