<template>
  <v-app>
    <GuideHeader />
    <v-main style="margin-top: 80px">
      <div style="width: 100%;display: flex;flex-direction: row;align-items: start;justify-content:start;height: 100%">
        <v-sheet style="min-width: 200px;width: 200px;height: 100%;background-color: #E5EDF6">
          <router-view class="view one" name="menu"></router-view>
        </v-sheet>
        <v-sheet style="width: 100%">
          <router-view class="view two" name="content"></router-view>
        </v-sheet>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import Footer from "../default/Footer.vue";
import GuideHeader from "./GuideHeader.vue";

export default {
  components: {GuideHeader, Footer},
  created() {
    this.$store.commit('setGuideMenuId',this.$route.name)
  }
}
</script>

<style>
.menu-list {
  height: 50px;
  cursor: pointer;
}

.menu-item{
  padding: 15px 8px;
}


.menu-item:hover {
  background-color: #f6d0d0;
}

.menu-active {
  color: red;
  font-weight: bolder;
}
</style>